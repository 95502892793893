import request from '@/utils/request'

export function downloadProfile(userID, profileID) 
{
     return request({
        url: '/cloudstorage/data/' + userID + "/" + profileID,
        method: 'get',
        responseType: 'arraybuffer'
    })
}

export function uploadProfile(userID, profileID, profileData) 
{
    return request({
        url: '/cloudstorage/data/' + userID + "/" + profileID,
        headers: { "content-type": "application/octet-stream" },
        method: 'put',
        data: profileData
    })
}

export function getProfileInfo(userID, profileID, profileData) 
{
    return request({
        url: '/cloudstorage/' + userID + "/" + profileID,
        method: 'get'
    })
}