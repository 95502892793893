<template>
  <div class="app-container">
    <el-tabs v-model="activeNames" @tab-click="handleClick" v-loading="loading">
      <el-tab-pane label="Download Profile" name="1">
        <el-form ref="form" :model="downloadProfileForm" label-width="200px">
          <el-form-item label="User Gooogle ID">
            <el-input v-model="downloadProfileForm.userGoogleID"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" size="small" @click="onDownloadProfile">Download Profile</el-button>
          </el-form-item>
        </el-form>
      </el-tab-pane>

      <el-tab-pane label="Upload Profile Overwrite" name="2">
        <el-form ref="form" :model="uploadProfileForm" label-width="200px">
          <el-form-item label="Overwrite User Google ID">
            <el-input v-model="uploadProfileForm.userGoogleID"></el-input>
          </el-form-item>
          <el-form-item>

          <el-upload
            class="upload-profile"
            ref="upload_profile"          
            action=""
            :limit="2"
            :on-change="handleUploadChange"
            :show-file-list="true"
            :auto-upload="false"
            :file-list="fileList"
            >
            <el-button slot="trigger" size="small" type="primary">Select File</el-button>            
            <el-button style="margin-left: 10px;" size="small" type="success" @click="onUploadProfile">Upload Profile</el-button>
          </el-upload>
           
          </el-form-item>
        </el-form>
      </el-tab-pane>

    </el-tabs>
  </div>
</template>


<script>
import { MessageBox, Message } from "element-ui";
import { Loading } from "element-ui";
import { isStringEmpty } from "@/utils/validate";

import {
  downloadProfile,
  uploadProfile,
  getProfileInfo
} from "@/api/usersprofile";


export default {
  name: "UserConditions",
  components: {},
  data() {
    return {
      form: {
        displayName: "",
        codeName: "",
        desc: ""
      },
      downloadProfileForm: {
        userGoogleID: ""
      },
      uploadProfileForm: {
        userGoogleID: "",
        data: undefined
      },
      userConditionsData: [],
      loading: false,
      activeNames: "1",
      fileList: []
    };
  },
  created() {
    
  },
  methods: {
    //---------------------------------------------------------
    onDownloadProfile(){

      if (isStringEmpty(this.downloadProfileForm.userGoogleID)) {
        Message({
          message: "User Google ID is empty",
          type: "error",
          duration: 5 * 1000
        });
        return;
      }

      this.loading = true;

     let profileID = this.downloadProfileForm.userGoogleID;

     downloadProfile("1", profileID)
        .then(response => {

          this.loading = false;

          //loadingInstance.close();
          /*
          Message({
            message: response,
            type: "success",
            duration: 5 * 1000
          });
          */

          //-----------------------------------------------------------------------
          // Response file data

          var fileURL = window.URL.createObjectURL(new Blob([response],
          {type: 'application/octet-stream'}));

          var fileLink = document.createElement('a');
   
          fileLink.href = fileURL;

          fileLink.setAttribute('download', 'profile_' + profileID);

          document.body.appendChild(fileLink);
                     
          fileLink.click();
          //-----------------------------------------------------------------------
        })
        .catch(error => {
          //loadingInstance.close();

          this.loading = false;

          Message({
            message: "download profile failed " + error,
            type: "error",
            duration: 5 * 1000
          });
        });
    },
    onUploadProfile(){

      if (isStringEmpty(this.uploadProfileForm.userGoogleID)) 
      {
        Message({
          message: "User Google ID is empty",
          type: "error",
          duration: 5 * 1000
        });
        return;
      }

        //Message({
        //    message: "upload " + this.uploadProfileForm.data,
        //    type: "error",
        //    duration: 5 * 1000
        //  });

        let profileID = this.uploadProfileForm.userGoogleID;
        let profileData = this.uploadProfileForm.data;

        if (profileData === undefined)
        {
          Message({
                    message: "User Profile is empty",
                    type: "error",
                    duration: 5 * 1000
                  });

          return;
        }

        this.loading = true;

        getProfileInfo("1", profileID)
        .then(response => {
        
          uploadProfile("1", profileID, profileData)
              .then(response => {

                this.loading = false;

                Message({
                  message: "Upload profile to " + profileID + " success (result: " + response.result + ")",
                  type: "success",
                  duration: 5 * 1000
                });

                this.$refs.upload_profile.clearFiles();
                
              })
              .catch(error => {
                //loadingInstance.close();
                this.loading = false;

                Message({
                  message: "Upload profile failed: " + error,
                  type: "error",
                  duration: 5 * 1000
                });
              });
        })
        .catch(error => {
          //loadingInstance.close();

          this.loading = false;

          Message({
            message: "upload profile faile: profile '" + profileID + "' not exist ",
            type: "error",
            duration: 5 * 1000
          });
        });
    },
    handleUploadChange(file, fileList) {
      
        this.fileList = fileList.slice(-1);
        //Message({
        //    message: 'change ' +  file.name + ", data:" + file.raw +", old data:" + this.uploadProfileForm.data,
        //    type: "error",
        //    duration: 5 * 1000
        //  });

          this.uploadProfileForm.data = undefined;

          var reader = new FileReader();
          reader.readAsArrayBuffer(file.raw);
          reader.onload = (event) => {

            this.uploadProfileForm.data = event.target.result;

            Message({
              message: 'File uploading success',
              type: "success",
              duration: 5 * 1000
            });
          }

          reader.onerror = (event) => {

            Message({
              message: 'File uploading failed ' + event,
              type: "error",
              duration: 5 * 1000
            });
          } 

    },

    //---------------------------------------------------------

    handleClick() {}
  }
};
</script>

<style lang="scss" scoped>
.userprofile-container {
  margin: 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
</style>
